<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="
                  require(`@/assets/images/3d-characters/illustration-forgot-password-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                  }.png`)
                "
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text v-if="isTokenValid">
                  <p class="text-2xl font-weight-semibold text--primary mb-2">{{ $t('setYourNewPassword') }} 🫡</p>
                  <p v-t="'enterYourNewSuperSecurePassword'" class="mb-2" />
                </v-card-text>
                <v-card-text v-else>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">{{ $t('linkExpired') }} ☹️</p>
                  <p v-t="'enterYourEmailResetPassword'" class="mb-2" />
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form v-if="isTokenValid" ref="resetPasswordForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('password')"
                      :rules="[validators.required, validators.passwordValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      maxlength="128"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    />
                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      :label="$t('confirmPassword')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('confirmPassword')"
                      :rules="[validators.required, validators.confirmPasswordValidator(confirmPassword, password)]"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      maxlength="128"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <v-btn block color="primary" type="submit" :loading="loading"> {{ $t('resetPassword') }} </v-btn>
                  </v-form>
                  <v-form v-else ref="resetPasswordForm" @submit.prevent="resendResetPasswordEmailFormSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      :rules="[validators.required, validators.emailValidator]"
                      :label="$t('email', { postfix: '' })"
                      :placeholder="$t('email', { postfix: '' })"
                      hide-details="auto"
                      class="mb-6"
                      maxlength="254"
                    ></v-text-field>
                    <v-btn block color="primary" type="submit" :loading="loading"> Resend password reset email </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm">
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span v-t="'backToLogin'" />
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import themeConfig from '@themeConfig'
import { confirmPasswordValidator, emailValidator, passwordValidator, required } from '@core/utils/validation'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const resetPasswordForm = ref(null)
    const password = ref('')
    const confirmPassword = ref('')
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const email = ref('')
    const loading = ref(false)
    const isTokenValid = ref(false)
    const errorMessages = ref([])

    const { router } = useRouter()

    const { verifyPasswordResetToken, resetPasswordRecruiterConfirm, sendRestRecruiterPasswordEmail } = useActions(
      'recruiter',
      ['verifyPasswordResetToken', 'resetPasswordRecruiterConfirm', 'sendRestRecruiterPasswordEmail'],
    )

    const isFormValid = () => {
      return resetPasswordForm.value.validate()
    }

    const handleFormSubmit = async () => {
      if (!isFormValid()) return
      loading.value = true
      await resetPasswordRecruiterConfirm({
        password: password.value,
        token: router.currentRoute.query.token,
      })
      loading.value = false
      setTimeout(async () => {
        await router.push('/')
      }, 1500)
    }

    const resendResetPasswordEmailFormSubmit = async () => {
      if (!isFormValid()) return
      loading.value = true
      await sendRestRecruiterPasswordEmail({
        email: email.value,
      })
      loading.value = false
      email.value = ''
    }

    onMounted(async () => {
      isTokenValid.value = await verifyPasswordResetToken({
        token: router.currentRoute.query.token,
      })
    })

    return {
      password,
      confirmPassword,
      isPasswordVisible,
      isConfirmPasswordVisible,
      email,
      loading,
      isTokenValid,
      errorMessages,
      handleFormSubmit,
      resendResetPasswordEmailFormSubmit,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      validators: {
        required,
        confirmPasswordValidator,
        passwordValidator,
        emailValidator,
      },

      icons: {
        mdiChevronLeft,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      resetPasswordForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
